import   client   from "@sanity/client";

export default client({
    projectId: 'utly4ngd',
    dataset: 'production',
    useCdn: true // Enable CDN
   
    // Other configuration options...
  });

  
