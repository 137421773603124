import { useState,useEffect } from 'react'
import React from 'react'
import client from '../client'

export default function Archiv() {
    const [posts, setPosts] = useState([])
  
  useEffect(() => {
    client
      .fetch(
        `*[_type == "achievemnt"]{
          description1,
          description2,
          description3,
          description4,
          title1,
          title2,
          title3,
          title4,

       
   
        
     
      
      }`
  
      )
      .then((data) => setPosts(data))
      .catch(console.error)
  }, [])
  return (
    <div> {posts.map((achievemnt) => ( 
         <section className="w3l-stats py-5" id="stats">
        <div className="gallery-inner container py-lg-5 py-md-4">
          <span className="title-small text-center mb-1">Our Achievements</span>
          <h3 className="title-big text-center mb-5">Our progress never Ends</h3>
          <div className="row stats-con">
            <div className="col-md-3 col-6 stats_info counter_grid">
              <p className="counter">{achievemnt.title1}</p>
              <span className="plus">+</span>
              <br />
              <h3>{achievemnt.description1}</h3>
            </div>
            <div className="col-md-3 col-6 stats_info counter_grid1">
              <p className="counter">{achievemnt.title2}</p>
              <span className="plus">+</span>
              <br />
              <h3>{achievemnt.description2}</h3>
            </div>
            <div className="col-md-3 col-6 stats_info counter_grid mt-md-0 mt-4">
              <p className="counter">{achievemnt.title3}</p>
              <span className="plus">+</span>
              <br />
              <h3>{achievemnt.description3}</h3>
            </div>
            <div className="col-md-3 col-6 stats_info counter_grid2 mt-md-0 mt-4">
              <p className="counter">{achievemnt.title4}</p>
              <span className="plus">+</span>
              <br />
              <h3>{achievemnt.description4}</h3>
            </div>
          </div>
        </div>
      </section>  ))}
    </div>
  )
}
