import React from "react";
import BreadCrumps from "../components/BreadCrumps";
import client from "../client"

import { useState, useEffect } from "react"

function Contact() {
  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    client
      .fetch(
        `*[_type == "contact"]{
       phone,
       adress,
      
      }`
  
      )
      .then((data) => setPosts(data))
      .catch(console.error)
  }, [])
  return (
    <div>
      <BreadCrumps page="Contact" title="Contact us" />

      {/* contact block */}
      {/* contact1 */}    {posts.map((contact) => ( 
      <section className="w3l-contact-1 pb-5" id="contact">
        <div className="contacts-9 py-lg-5 py-md-4">
          <div className="container">
            <div className="d-grid contact-view">
              <div className="cont-details">
                <h4 className="title-small">Get in touch</h4>
                <h3 className="title-big mb-4">Feel free to contact us</h3>
                <p className="mb-sm-5 mb-4">
                  Start  with Us, We guarantee that you’ll be able to
                  have any issue resolved within 24 hours.
                </p>

                <div className="cont-top">
                  <div className="cont-left text-center">
                    <span className="fa fa-map-marker text-primary"></span>
                  </div>
                  <div className="cont-right">
                    <h6>Our head office address</h6>
                    <p className="pr-lg-5">
                   {contact.adress}
                    </p>
                  </div>
                </div>
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fa fa-phone text-primary"></span>
                  </div>
                  <div className="cont-right">
                    <h6>Call for help </h6>
                    <p>
                      <a href="tel:+212690942656">+{contact.phone} </a>
                    </p>
                  </div>
                </div>
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fa fa-envelope-o text-primary"></span>
                  </div>
                  <div className="cont-right">
                    <h6>Contact with our support</h6>
                    <p>
                   
                    <a href="mailto:tourandtravelmorocco@gmail.com" className="mail">
                    tourandtravelmorocco@gmail.com
                      </a>
                     
                    </p>
                  </div>
                </div>
              </div>
              <div className="map-content-9">
              
              </div>
            </div>
          </div>
        </div>
      </section>    ))}
      {/* /contact1 */}
  {/* /contact1 */}
  <div className="map-iframe">
        <iframe
          title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d217405.76170782922!2d-8.172670348118846!3d31.634807936033805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafee8d96179e51%3A0x5950b6534f87adb8!2sMarrakesh!5e0!3m2!1sen!2sma!4v1708312645776!5m2!1sen!2sma"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"
        width="100%"
          height="400"
          frameBorder="0"
          style={{ border: "0px" }}
       
        ></iframe>
      </div>
      {/* //contact block */}
    
    </div>
  );
}

export default Contact;
