import React from "react";



function Footer() {
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  return (
    <div>
      {/* footer */}
      <section className="w3l-footer-29-main">
        <div className="footer-29 py-5">
          <div className="container py-md-4">
            <div className="row footer-top-29">
              <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5">
                <h6 className="footer-title-29">Contact Info </h6>
                <p>
                  Address : Dar Nabila, 71 Derb Derdouba 40000 Marrakech
                </p>
                <p className="my-2">
                  Phone : <a href="tel:+212690942656">+(212)690942656</a>
                </p>
                <p>
                  Email :<a href="mailto:tourandtravelmorocco@gmail.com" className="mail">tourandtravelmorocco@gmail.com
                      </a>
                </p>
               
              </div>
      
              <div className="col-lg-3 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-sm-0 mt-5">
                <ul>
                  <h6 className="footer-title-29">Company</h6>
                  <li>
                    <a href="/about">About company</a>
                  </li>
                 
                 
                  <li>
                    <a href="/trips">all  Excutions</a>
                  </li>
                  <li>
                    <a href="/contact">Get in touch</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-3 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Programs</h6>
                <ul>
                  <li>
                    <a href="/trips">1 Day Excutions</a>
                  </li>
                  <li>
                    <a href="/trips">2 Day Excutions</a>
                  </li>
                  <li>
                    <a href="/trips">3 or more Day Excutions</a>
                  </li>
             
                </ul>
              </div>
          
            </div>
          </div>
        </div>
        {/* copyright */}
        <section className="w3l-copyright text-center">
          <div className="container">
     
            <p className="copy-footer-29">
              © 2023 Tour And Travel Morocco. All rights reserved.
              <a rel="noreferrer" href="/" target="_blank">
                
              </a>
            </p>
          </div>      
          {/* move top */}
          <button onClick={topFunction} id="movetop" title="Go to top">
            &#10548;
          </button>

          {/* /move top */}
        </section>
        {/* //copyright */}
      </section> 
      {/* //footer */}
   
    
    </div>
  );
}

export default Footer;
