import React from "react";
import BreadCrumps from '../components/BreadCrumps'
import Archiv from "../components/archiv";
import client from "../client"

import { useState, useEffect } from "react"
function About() {
  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    client
      .fetch(
        `*[_type == "about"]{
          title,
          description,
          description1,
          description2,
          mainImage {
            asset -> {
              _id,
              url
            } } ,
       
      
      }`
  
      )
      .then((data) => setPosts(data))
      .catch(console.error)
  }, [])
  return (
    <div>
      <BreadCrumps  page="About us"  title="About Company"  />
      {posts.map((about) => ( 

      <section className="w3l-aboutblock1 py-5" id="about">
      
        <div className="container py-lg-5 py-md-4 py-2">
       
          <div className="row">
            <div className="col-lg-6 align-self">
              <span className="title-small mb-2">About Us</span>
              <h3 className="title-big mx-0">
              {about.title}
              </h3>
              <p className="mt-lg-4 mt-3">
              {about.description1}
              </p>
              <p className="mt-3 mb-lg-5">
              {about.description2}
              </p>
            </div>
            <div className="col-lg-6 left-wthree-img mt-lg-0 mt-sm-5 mt-4">
              <img
                 src={about.mainImage.asset.url} 
                alt=""
                className="img-fluid radius-image"
              />
            </div>
          </div>
        </div>
      </section>
        ))}

  <Archiv/>
      {/* stats */}
     
      {/* //stats */}

    </div>
  );
}

export default About;
