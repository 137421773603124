import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {

  return (
    <div>
      {/*header*/}
      <header id="site-header" className=" fixed-top bg-blueviolet;" >
    
        <div className="container">
   
          <nav className="navbar navbar-expand-lg navbar-dark stroke">
     
      <div className="navbar-brand" href="/">
      
     <img  src="assets/images/lo1.png" alt="Your logo" title="Your logo"  href="/"/> 
          <a className="navbar-brand" href="/">
             
             <span className="logo"> TOUR AND TRAVEL MOROCCO</span>
           </a>
          </div>
        
         

            {/* if logo is image enable this   
                        <a className="navbar-brand" href="#/">
                            <img src="image-path" alt="Your logo" title="Your logo" style={{height:"35px}};" />
                        </a> 
                    */}
            <button
              className="navbar-toggler  collapsed bg-gradient"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
              <span className="navbar-toggler-icon fa icon-close fa-times"></span>
            </button>

            <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-lg-auto ">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item @@about__active">
                  <a className="nav-link" href="/about">
                    About
                  </a>
                </li>
                <li className="nav-item @@courses__active">
                  <a className="nav-link"  href="/trips">
                   All Tours
                  </a>
                </li>
                <li className="nav-item @@contact__active">
                  <a className="nav-link"  href="/contact">
                  Contact
                  </a>
                </li>
                 
           
              </ul></div>

        
            {/* toggle switch for light and dark theme */}
           
            {/* //toggle switch for light and dark theme */}
          </nav>
        </div>
      </header>
      {/*/header*/}
    </div>
  );
}

export default Header;
