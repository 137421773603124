import React from "react";
import BreadCrumps from "../components/BreadCrumps";
import Trips from "../layout/trip";


function Courses() {
  return (
    <div>
        <BreadCrumps page="All Trips"  title="trips"  />
    <Trips/>
    </div>
  );
}

export default Courses;
