import React from 'react'
import BTrips from '../components/treebtrip'
import Archiv from '../components/archiv'

import { useState,useEffect } from 'react'

import client from '../client'

function Home() {
    const [posts, setPosts] = useState([])
  
    useEffect(() => {
      client
        .fetch(
          `*[_type =="home"]{
            description1,
            description,
            descriptionboton,
        
         
        
        }`
    
        )
        .then((data) => setPosts(data))
        .catch(console.error)
    }, [])
  return (
    <div>


<section className="w3l-main-slider" id="home">
<div className="companies20-content">
    <div className="owl-one owl-carousel owl-theme">
        <div className="item">
            <li>
                <div className="slider-info banner-view bg bg2" >
                 
                    <div className="banner-info">
                        <div className="container">
                        {posts.map((home) => (  
                        <div className="banner-info-bg">
                                <h5>{home. description}</h5>
                                <p className="mt-4 pr-lg-4">
                                {home. description1}
                                </p>
                                <a className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" href="/trips"> 
                                {home. descriptionboton}
                                </a>
                            </div> ))}
                        </div>
                    </div>
                </div>
            </li>
        </div>
        <div className="item">
            <li>
                <div className="slider-info  banner-view banner-top1 bg bg2">
                    <div className="banner-info">
                        <div className="container">
                        {posts.map((home) => ( 
                        <div className="banner-info-bg">
                                <h5>{home. description}</h5>
                                <p className="mt-4 pr-lg-4">
                                {home. description1}
                                </p>
                                <a className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" href="/trips"> 
                                {home. descriptionboton}
                                </a>
                            </div>      ))}
                        </div>
                    </div>
                </div>
            </li>
        </div>
        <div className="item">
            <li>
                <div className="slider-info banner-view banner-top2 bg bg2">
                    <div className="banner-info">
                        <div className="container">{posts.map((home) => (  
                            <div className="banner-info-bg">
                                <h5>{home. description}</h5>
                                <p className="mt-4 pr-lg-4">
                                {home. description1}
                                </p>
                                <a className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" href="/trips"> 
                                {home. descriptionboton}
                                </a>
                            </div> ))}
                        </div>
                    </div>
                </div>
            </li>
        </div>
        <div className="item">
            <li>
                <div className="slider-info banner-view banner-top3 bg bg2">
                    <div className="banner-info">
                        <div className="container">
                        {posts.map((home) => ( 
                        <div className="banner-info-bg">
                                <h5>{home. description}</h5>
                                <p className="mt-4 pr-lg-4">
                                {home. description1}
                                </p>
                                <a className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2" href="/trips"> 
                                {home. descriptionboton}
                                </a>
                            </div>  ))}
                        </div>
                    </div>
                </div>
            </li>
        </div>
    </div>
</div>

<div className="waveWrapper waveAnimation">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path d="M-5.07,73.52 C149.99,150.00 299.66,-102.13 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style={{stroke: "none"}}></path>
    </svg>
</div>
<div></div>

</section>

     
        <section className="w3l-courses">
        
            <div className="blog pb-5" id="courses">
                <div className="container py-lg-5 py-md-4 py-2">
                    <h5 className="title-small text-center mb-1">see our all tour and desinatios</h5>
                    <h3 className="title-big text-center mb-sm-5 mb-4">best tour <span>morocco tour</span></h3>
                    <div className="row">
                    <BTrips/>
                    
                    
                    </div>
                   
                    </div>  
            </div>
        </section>
        
       

        <div className="w3l-homeblock3 py-5">
            <div className="container py-lg-5 py-md-4 py-2">
                <h5 className="title-small text-center mb-1">
                   our trips
                </h5>
                <h3 className="title-big text-center mb-sm-5 mb-4">
                    Latest trips
                
                </h3>
                <div className="row top-pics">
                    <div className="col-md-6">
                        <div className="top-pic1">
                            <div className="card-body blog-details">
                                <a href="/trips" className="blog-desc">
                                    Enjoy 1 day trip while you are in marrakech lit us to do the best for you 
                                </a>
                                <div className="author align-items-center">
                                    <img src="assets/images/lo1.png" alt="blogs" className="img-fluid rounded-circle" />
                                    <ul className="blog-meta">
                                    <li>
                                                <a > tour and travel morocco</a>
                                            </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-md-0 mt-4">
                        <div className="top-pic2">
                            <div className="card-body blog-details">
                                <a href="/trips" className="blog-desc">
                                Enjoy 2 days trip while you are in marrakech lit us to do the best for you 

                                </a>
                                <div className="author align-items-center">
                                    <img src="assets/images/lo1.png" alt="" className="img-fluid rounded-circle" />
                                    <ul className="blog-meta">
                                    <li>
                                                <a href="#author"> tour and travel morocco</a>
                                            </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="top-pic3">
                                <div className="card-body blog-details">
                                    <a href="/trips" className="blog-desc"> 
                                    Enjoy 3 or more days  trip while you are in marrakech lit us to do the best for you 

                                    </a>
                                    <div className="author align-items-center">
                                        <img src="assets/images/lo1.png" alt="" className="img-fluid rounded-circle" />
                                        <ul className="blog-meta">
                                            <li>
                                                <a > tour and travel morocco</a>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-md-5 mt-4 text-more text-center">
                    <a href="/trips">
                        View All Tours
                        <span className="pl-2 fa fa-long-arrow-right"></span>
                    </a>
                </div>
            </div>
        </div>
     
      
        {/* //middle */}
      <Archiv/>




    </div>  )
}

export default Home