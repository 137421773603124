import React from "react";


import { BrowserRouter, Routes, Route } from "react-router-dom";

import ReactWhatsappButton from "react-whatsapp-button";



import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Header from "./components/Header";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import Tripinfo from "./layout/tripinfo";
import Text from "./components/text";







function App() {
  return (
    <div >
         <ReactWhatsappButton
        countryCode="+212"
        phoneNumber="690942656"
        style={{
          
            right: "unset",
            left: "10px",
        }}
      />
 
    <Header/>
   <BrowserRouter>
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/trips" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/trip/:slug" element={<Tripinfo />} />
          <Route path="/text" element={<Text />} />


         
        </Routes>
      </BrowserRouter>
   
    <Footer/>
   </div>
)}
export default App