
import { useState, useEffect } from "react"
import {  useParams } from "react-router-dom"
import client from "../client"
import BlockContent from "@sanity/block-content-to-react"
import BreadCrumps from "../components/BreadCrumps"

function Tripinfo() {
  const [singlePost, setSinglePost] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { slug } = useParams()

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
          mainImage1 {
            asset -> {
              _id,
              url
            },
            alt
          } ,
        
          mainImage2 {
            asset -> {
              _id,
              url
            },
            alt
          } ,

          mainImage3 {
            asset -> {
              _id,
              url
            },
            alt
          } ,
          mainImage4 {
            asset -> {
              _id,
              url
            },
            alt
          } ,
       
          
        tripname,
        title,
        body,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
       
      }`
      )
      .then((data) => setSinglePost(data[0]))
    setIsLoading(false)
  }, [slug])
  return (<div>
         <BreadCrumps page="trips" title="information about trip" />
  {isLoading ? (
        <h1 className="uppercase font-bold text-4xl tracking-wide mb-5 md:text-6xl lg:text-8xl flex items-center justify-center h-screen">
          Loading...
        </h1>
      ) : (
  
     <section className="w3l-team py-5" id="team">
      <div className="call-w3 py-lg-5 py-md-4">
         <div className="container">
          <div className="row main-cont-wthree-2">
              <div className="col-lg-5 feature-grid-left">
                  <p className="title-small mb-1">
                  {singlePost.tripname}
                 
                  </p>
                  <h3 className="title-big mb-4">
                  {singlePost.tripname}
                  </h3>
                  {singlePost.description && singlePost.description && (

                 <p className="text-para">
                   {singlePost.description1}
                    </p>  )}
                  <p className="mt-3">
                  {singlePost.description2}
                  </p>
                  <BlockContent
              blocks={singlePost.body}
              projectId="utly4ngd"
              dataset="production"
            />
                  <a href="/contact" className="btn btn-primary btn-style mt-md-5 mt-4">
                    Contact us for more information
                  </a>
          
              </div>
              <div className="col-lg-7 feature-grid-right mt-lg-0 mt-5">
                  <div className="row">
                      <div className="col-sm-6">
                          <div className="box16">
                          {singlePost.mainImage1 && singlePost.mainImage1.asset && (
            <img
              src={singlePost.mainImage1.asset.url}
              alt={singlePost.title}
              title={singlePost.title}
              className="img-fluid radius-image"
            />
          )}
                 
                             
                          
                          </div>
                      </div>
                      <div className="col-sm-6">
                          <div className="box16">
                          {singlePost.mainImage2 && singlePost.mainImage2.asset && (
            <img
              src={singlePost.mainImage2.asset.url}
              alt={singlePost.title}
              title={singlePost.title}
              className="img-fluid radius-image"
            />
          )}
                 
                             
                          
                          </div>
                      </div>
                      <div className="col-sm-6">
                          <div className="box16">
                          {singlePost.mainImage3 && singlePost.mainImage3.asset && (
            <img
              src={singlePost.mainImage3.asset.url}
              alt={singlePost.title}
              title={singlePost.title}
              className="img-fluid radius-image"
            />
          )}
                 
                             
                          
                          </div>
                      </div>
                      <div className="col-sm-6">
                          <div className="box16">
                          {singlePost.mainImage4 && singlePost.mainImage4.asset && (
            <img
              src={singlePost.mainImage4.asset.url}
              alt={singlePost.title}
              title={singlePost.title}
              className="img-fluid radius-image"
            />
          )}
                 
                             
                          
                          </div>
                      </div>
                    
                 
                  </div>
              </div>
          </div>
        
      </div>
  </div>
</section>  
   )}</div>
   )
}

export default Tripinfo