import { useState,useEffect } from 'react'
import React from 'react'
import client from '../client'
import { Link } from 'react-router-dom'
function BTrips() {
  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"]| order(_createdAt desc) [0..2]{
        title,
        slug,
        body,
        prix,
        tripname,
        daystrip,
        persens,
        hours,
        mainImage {
          asset -> {
            _id,
            url
          } } ,
        mainImage1 {
          asset -> {
            _id,
            url
          },
          alt
        }
      
      }`
  
      )
      .then((data) => setPosts(data))
      .catch(console.error)
  }, [])
  return (
    <section className="w3l-courses">
        
    <div className="blog pb-5" id="courses">
        <div className="container py-lg-5 py-md-4 py-2">
           
            <div className="row">
        {posts.map((post) => ( 
  
     <div className="col-lg-4 col-md-6 item mt-md-0 mt-5"    >
        <div className="card" >
       
            <div className="card-header p-0 position-relative">
              <Link    to={`/trip/${post.title}`}  >
                    <a href="#author" className="zoom d-block" >
                
                    
                      <img src={post.mainImage.asset.url}   className="card-img-bottom d-block" alt={post.title} />
                    </a></Link>
                    <div className="course-price-badge-new"> {post.daystrip} day</div>
                  </div>
                  <div className="card-body course-details">
                    <div className="price-review d-flex justify-content-between mb-1align-items-center">
                      <p>${post.prix}</p>
                      <ul className="rating-star">
                        <li>
                          <span className="fa fa-star"></span>
                        </li>
                        <li>
                          <span className="fa fa-star"></span>
                        </li>
                        <li>
                          <span className="fa fa-star"></span>
                        </li>
                        <li>
                          <span className="fa fa-star"></span>
                        </li>
                        <li>
                          <span className="fa fa-star-o"></span>
                        </li>
                      </ul>
                    </div>
                    <Link    to={`/trip/${post.title}`}  >
                    <a  href="#author"  className="course-desc">
                    {post.tripname}
                    </a></Link>
                    <div className="course-meta mt-4">
                      <div className="meta-item course-lesson">
                        <span className="fa fa-clock-o"></span>
                        <span className="meta-value"> {post.hours} hrs </span>
                      </div>
                      <div className="meta-item course-">
                        <span className="fa fa-user-o"></span>
                        <span className="meta-value"> {post.persens}</span>
                      </div>
                    </div>
                  </div>
          <div className="card-footer">
            <div className="author align-items-center">
                <img src='assets/images/lo1.png' alt="" className="img-fluid rounded-circle" />
                <ul className="blog-meta">
                    <li>
                        <span className="meta-value mx-1">by</span> <a href="/"> Tour And Travel</a>
                    </li>
                  
                </ul>
            </div>
        </div>
       
        </div>
        
    

        
   </div>
      ))}
       </div>
                   
                   </div>  
           </div>
       </section>
   
  )
}

export default BTrips